/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.5.0 public/models/kettlebell/BlackModel.glb 
*/

import React from "react";
import { useGLTF } from "@react-three/drei";

export function Model(props) {
  const { nodes, materials } = useGLTF(
    process.env.PUBLIC_URL + "/models/kettlebell/BlackModel.glb"
  );
  return (
    <group {...props} dispose={null}>
      <group position={[0, 0, 0]}>
        <group>
          <group scale={1.631}>
            <mesh
              geometry={nodes.Object_4.geometry}
              material={materials.iron}
            />
            <mesh
              geometry={nodes.Object_5.geometry}
              material={materials.yellow}
            />
          </group>
          <mesh
            geometry={nodes.Object_7.geometry}
            material={materials.material}
            scale={0.699}
          />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload(process.env.PUBLIC_URL + "/models/kettlebell/BlackModel.glb");
